<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption">授权</v-subheader>
    <ModifyPass></ModifyPass>
    <ClearData></ClearData>
    <Logout></Logout>
    <v-subheader class="text-caption">已占用存储空间{{ getDataSize }}</v-subheader>
  </v-card>
</template>

<script>
import ClearData from '../dialogs/ClearData'
import ModifyPass from '../dialogs/ModifyPass'
import Logout from '../dialogs/Logout'
import { localStorageSpace } from 'common/utils'
export default {
  name: "AuthCard",
  data() {
    return {
      dataSize: ''
    }
  },
  components: {
    ClearData,
    ModifyPass,
    Logout
  },
  computed: {
    getDataSize() {
      return localStorageSpace()
    }
  }
}
</script>

<style scoped>
</style>