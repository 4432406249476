<template>
  <v-card class="mx-3">
    <v-subheader class="text-caption">设置</v-subheader>
      <v-list-item
        v-for="set in sets"
        :key="set.text"
        :to="'/profile' + set.path"
        dense
      >
        <v-list-item-content>
          <v-list-item-title v-text="set.text" class="font-weight-regular"></v-list-item-title>
        </v-list-item-content>
        <v-spacer></v-spacer>

        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "SetCard",
  data: () => ({
    tansitionName: "scroll-x-reverse-transition",
    dialog: false,
    currentDialog: "about",
    sets: [
      {
        text: "关于我们",
        path: "/about",
      },
      {
        text: "卡片设置",
        path: "/cardset",
      },
      {
        text: "更新日志",
        path: "/updatelog",
      },
      {
        text: "反馈",
        path: "/feeback",
      },
      {
        text: "赞助",
        path: "/sponsor"
      }
    ],
  }),
  methods: {
  },
};
</script>

<style scoped>
</style>