<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" dense>
        <v-list-item-content class="red--text">
          <v-list-item-title class="font-weight-regular"
            >注销账户</v-list-item-title
          >
        </v-list-item-content>
        <v-spacer></v-spacer>

        <v-list-item-icon>
          <v-icon class="red--text">mdi-alert-circle</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="text-subtitle-1">
        <v-row no-gutters justify="center">确认注销?</v-row>
      </v-card-title>

      <v-card-text class="text-center">
        注销之后该账户就不可用了哦，也不能享受我们提供的任何服务
        <v-text-field
          label="确认注销"
          value=""
          v-model="inputVal"
          messages="请在上方输入`确认注销`来注销账户"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="logout">注销</v-btn>
        <v-btn text @click="dialog = false">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { logout } from 'network/auth'

export default {
  name: "Logout",
  data: () => ({
    dialog: false,
    inputVal: ''
  }),
  methods: {
    logout() {
      if (this.inputVal === '确认注销') {
        this.reqLogout()
      } else {
        this.$notify({ title: '请输入确认注销', type: 'warning', duration: 1500 })
      }
    },
    reqLogout() {
      logout().then(res => {
        this.$notify({
          title: '账户注销成功',
          type: 'success',
          duration: 1500
        })
        this.dialog = false
        this.$store.commit('clear_token')
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<style>
</style>