<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption">我的个人信息</v-subheader>
    <v-row no-gutters v-for="(value, key) in items" :key="key" align="center" justify="center" class="pb-2">
        <v-col class="px-4 text-body-2">{{value}}</v-col>
        <v-col cols="6" sm="4" class="text-body-2">{{ infos[key] }}</v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'InfoCard',
  data: () => ({
    infos: {
      name: 'GideonSenku',
      depart: '不写代码分院',
      class: '22软件工程-7班'
    },
    items: {
      name: '姓名',
      depart: '分院',
      class: '班级'
    }
  })
}
</script>

<style scoped>

</style>