<template>
  <!-- 修改密码 -->
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" dense>
        <v-list-item-content>
          <v-list-item-title class="font-weight-regular"
            >修改密码</v-list-item-title
          >
        </v-list-item-content>
        <v-spacer></v-spacer>

        <v-list-item-icon>
          <v-icon>mdi-key-minus</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <v-card :loading="loading">
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>修改密码</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="旧密码"
          value=""
          :type="inputType"
          v-model="oldpass"
        >
        <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
        </v-text-field>
        <span class="caption grey--text text--darken-1">
          
        </span>
        <v-text-field
          label="新密码"
          :type="inputType"
          v-model="newpass"
          :rules="rulesForPassWord"
        >
          <template v-slot:append><v-icon v-text="iconEye" @click="change"></v-icon></template>
        </v-text-field>
        <span class="caption grey--text text--darken-1">
          请输入登录「Na+课表」账户的新密码
        </span>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handerModifyPass">确定修改</v-btn>
        <v-btn text @click="dialog = false">取消</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="1500" multi-line top>{{ msg}}</v-snackbar>
  </v-dialog>
</template>

<script>
import { rulesForPassWord } from 'common/const'
import { eyeChangeMixin } from 'common/mixin'
import { changePassWord } from 'network/auth'

export default {
  name: "ModifyPass",
  mixins: [eyeChangeMixin],
  data: () => ({
    oldpass: '',
    newpass: '',
    dialog: false,
    loading: false,
    snackbar: false,
    msg: '',
    rulesForPassWord,
  }),
  methods: {
    /**
     * desc: 输入框合法性校验
     * params func: 接收一个函数去调用
     */
    check(func) {
      const checkOld = rulesForPassWord[2](this.oldpass)
      const checkNew = rulesForPassWord[2](this.newpass)
      if (checkOld === true && checkNew === true) {
        func()
      } else {
        this.showSnackBar('密码至少一个大写、一个小写字母、一个数字,且长度为8到16位')
      }
    },
    handerModifyPass() {
      this.loading = true
      this.check(this.reqModifyPass)
    },
    reqModifyPass() {
      changePassWord(this.oldpass, this.newpass)
      .then(res => {
        this.showSnackBar('密码修改成功')
        setTimeout(() => {
          this.dialog = false
        }, 1000)
      })
      .catch(err => {
        this.showSnackBar('旧密码不正确')
      })
    },
    showSnackBar(msg) {
      this.loading = false
      this.msg = msg
      this.snackbar = true
    }
  },
}
</script>

<style>
</style>