<template>
  <v-col align="center" lg="1" md="2" sm="4">
    <v-avatar rounded :size="size" @click="switchView">
      <v-img :src="img"></v-img>
    </v-avatar>
    <div class="text-caption mt-1 mb-2">{{ title }}</div>
  </v-col>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    cols: {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
    },
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
    },
    path: {
      type: String
    }
  },
  methods: {
    switchView() {
      if (this.path) {
        this.$router.push(this.path)
      } else {
        this.$emit('icoBtnClick')
      }
    }
  }
}
</script>

<style>
</style>