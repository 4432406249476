import OverFlowY from "components/common/OverFlowY"
import TopBar from 'components/common/TopBar'
import TopInfo from 'components/common/TopInfo'
import BackIcon from 'components/common/BackIcon'

// 当屏幕大于700时为大屏手机
export const isLargeScreenMixin = {
  components: {
    OverFlowY
  },
  computed: {
    isLargeScreen() {
      return window.innerHeight > 700 
    }
  }
}

// 减少重复当代码，设置子页面都需要用到的通用组件
export const setChildViewMixin = {
  components: {
    TopBar,
    TopInfo,
    BackIcon
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    }
  }
}

// 点击事件，改变密码是否可见
export const eyeChangeMixin = {
  data: () => ({
    iconEye: 'mdi-eye',
    inputType: 'password',
  }),
  methods: {
    change() {
      this.changeIcon()
      this.changeInputType()
    },
    changeIcon() {
      this.iconEye === 'mdi-eye-off' ? this.iconEye = 'mdi-eye' : this.iconEye = 'mdi-eye-off'
    },
    changeInputType() {
      this.inputType === 'password' ? this.inputType = 'text' : this.inputType = 'password'
    }
  }
}
