<template>
  <div class="version">
    <v-row justify="center" no-gutters class="py-4">
      <v-avatar size="70" rounded>
        <v-img :src="img" color="white"></v-img>
      </v-avatar>
    </v-row>
    <v-row justify="center" no-gutters class="mb-7">
      <div :class="textClass">{{title}}</div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TopInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    textClass: {
      type: String,
      default: 'text-caption'
    }
  }
}
</script>

<style scoped>
</style>