<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" dense>
        <v-list-item-content>
          <v-list-item-title class="font-weight-regular"
            >清除数据</v-list-item-title
          >
        </v-list-item-content>
        <v-spacer></v-spacer>

        <v-list-item-icon>
          <v-icon>mdi-alert-circle</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title class="text-subtitle-1">
        <v-row no-gutters justify="center">确认清除数据?</v-row>
      </v-card-title>

      <v-card-text class="text-center">
        清除数据将清除课程、电费等本地信息
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="clearStorge">确定</v-btn>
        <v-btn text @click="dialog = false">取消</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ClearData",
  data: () => ({
    dialog: false,
  }),
  methods: {
    clearStorge() {
      this.dialog = false
      this.$store.commit('clear_token')
      this.$notify({
        title: '清除数据成功',
        type: 'success',
        duration: 1500
      })
    },
  }
}
</script>

<style>
</style>