<template>
  <v-container class="py-4">
    <v-row no-gutters>
      <IconButton :img="imgPath" :title="nickname" :cols="12" @icoBtnClick="bind" path="/profile/update"></IconButton>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="1500" top >修改信息功能还在施工中哦:)</v-snackbar>
  </v-container>
</template>

<script>
import IconButton from 'components/common/IconButton'
import account from 'assets/img/account.png'
import { getProfile } from 'network/user'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Avatar',
  components: {
    IconButton,
  },
  data: () => ({
    snackbar: false,
    imgPath: account
  }),
  created() {
    if (!this.nickname) {
      this.getUserInfo()
    }
  },
  methods: {
    ...mapActions(['getUserInfo']),
    bind() {
      console.log("Avatar-bind method");
      this.snackbar = true
    }
  },
  computed: {
    ...mapState({
      nickname: state => state.userInfo.nickname
    })
  }
}
</script>
