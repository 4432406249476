<template>
  <!-- 解决小屏幕手机滚动问题 -->
  <v-responsive class="overflow-y-auto">
    <v-responsive :height="height"></v-responsive>
  </v-responsive>
</template>

<script>
export default {
  name: "OverFlowY",
  props: {
    height: {
      type: Number,
      default: 70
    }
  }
}
</script>

<style scoped>
</style>