<template>
  <v-img @click="setDark" class="setdark">
    <v-icon v-text="isDark ? `mdi-lightbulb-on-outline` : `mdi-lightbulb-outline`"></v-icon>
  </v-img>
</template>

<script>
export default {
  name: "SetDark",
  methods: {
    setDark() {
      const dark = this.isDark
      this.$vuetify.theme.dark = !dark
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark
    }
  }
}
</script>

<style>
.setdark {
  top: 4px;
}
</style>